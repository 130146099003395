import React from 'react';
import './App.css';
import { FaInstagram, FaEnvelope, FaLinkedin, FaGithub,FaWhatsapp } from 'react-icons/fa'; 


import videoSource from './assets/arkaplan.mp4'; 
import alperpp from './assets/alpernew.jpg'; 

function App() {
    return (
        <div className="app">
            <video className="background-video" autoPlay loop muted>
                <source src={videoSource} type="video/mp4" />
            </video>
            <div className="content">
    
                <div className="card">
                    <img className="profile-image" src={alperpp} alt="Profil" />
                   
                    <h2>Alper Kılıç</h2>
                    <p>Merhaba,İstanbul Üniversitesi Yönetim Bilişim Sistemleri bölümünde okuyorum. Bilişim sistemleri ile ilgilenmeyi seven, aynı zamanda AI (ARTIFICIAL INTELLIGENCE) alanına merak salmış ve kendini geliştirmeye çalışan birisiyim. Gelecekte de kendimi bu alanda profesyonel seviyeye getirip bu alanda çalışmak istiyorum.</p>
                    
                    <div className="icons">
                        <a href="https://instagram.com/iam4lper" target="_blank" rel="noopener noreferrer">
                            <FaInstagram size={30} />
                        </a>
                        <a href="https://instagram.com/unusjwqq" target="_blank" rel="noopener noreferrer">
                            <FaInstagram size={30} />
                        </a>
                        
                     
                        <a href="mailto:alper@klcdev.com">
                            <FaEnvelope size={30} />
                        </a> 
                        <a href="https://github.com/talperkilic">
                            <FaGithub size={30} />
                        </a> 
                        <a href="https://linkedin.com/in/talperkilic" target="_blank" rel="noopener noreferrer">
                      <FaLinkedin size={30} />
                      </a>

                      <a href="https://api.whatsapp.com/send/?phone=212675382182&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">
                      <FaWhatsapp size={30} />
                      </a>
                        
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
